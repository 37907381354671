[data-amplify-router] {
  padding: 48px 40px;
  border-radius: 24px;
  border: none !important;
  box-shadow: 0px 10px 40px 0px #0000001a !important;
  background: #645c90 !important;
}

[data-amplify-container] {
  width: 492px !important;
}

.amplify-tabs {
  display: none;
}

[data-amplify-form] {
  padding: 0px !important;
}

[data-amplify-form] > div.amplify-flex {
  gap: 16px;
}

fieldset.amplify-flex {
  gap: 16px !important;
}

.amplify-button[data-variation="primary"] {
  height: 56px;
  border-radius: 32px;
  font-family: sofia-pro;
  font-weight: 800 !important;
  font-size: 16px;
  background: #e7d54f;
  color: #645c90;
  transition: color 250ms, background 250ms;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  margin-top: 16px;
}

.amplify-button[data-variation="primary"]:hover {
  background: #645c90;
  color: #e7d54f;
}

.amplify-button[data-variation="primary"]:disabled {
  background: #b1aec4;
  color: #827cac;
}

.amplify-button[data-variation="primary"]::after {
  width: 12px;
  height: 12px;
  display: inline-block;
  content: "";
  -webkit-mask: url(./arrow.svg) no-repeat 50% 50%;
  mask: url(./arrow.svg) no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: currentColor;
  margin-left: 16px;
}

.amplify-input {
  color: #645c90;
  background: #ffffff;
  border: none !important;
  border-radius: 32px;
  height: 52px;
  box-shadow: none !important;
  font-family: sofia-pro;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  padding: 16px 24px;
}

.amplify-input:focus {
  border-color: #a57fa0 !important;
}

.amplify-input--error {
  border-color: #b93a2e !important;
}

.amplify-input::placeholder {
  font-family: sofia-pro;
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 300;
  color: #645c90;
}

.amplify-input[type="password"] {
  border-right: none !important;
}

.amplify-field__show-password {
  box-shadow: none !important;
  color: #645c90 !important;
  background: #ffffff !important;
  border: none !important;
  border-radius: 32px;
  padding: 0px 24px 0px 12px;
  width: fit-content;
  justify-content: flex-end;
}

.amplify-field__show-password > .amplify-icon {
  width: fit-content;
  height: fit-content;
}

.amplify-field__show-password svg {
  width: 20px;
  height: 20px;
}

.amplify-text {
  font-family: sofia-pro !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.amplify-text[data-variation="error"] {
  color: #f56b55;
}

.amplify-button[data-variation="link"] {
  height: fit-content;
  width: fit-content;
  align-self: center;
  font-family: sofia-pro;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: underline;
}

.amplify-button[data-variation="link"]:hover {
  background: transparent;
}

[data-amplify-authenticator-confirmsignup] .amplify-button:not([data-variation]) {
  height: 56px;
  border-radius: 32px;
  font-family: sofia-pro;
  font-weight: 800 !important;
  font-size: 16px;
  background: #ffffff;
  color: #645c90;
  transition: color 250ms, background 250ms, border 250ms;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  margin-top: 16px;
}

[data-amplify-authenticator-confirmsignup] .amplify-button:not([data-variation]):hover {
  background: #645c90;
  color: #ffffff;
}

[data-amplify-authenticator-confirmsignup] .amplify-button:not([data-variation]):disabled {
  background: #ffffff;
  color: #b1aec4;
}

.amplify-authenticator__subtitle {
  color: #ffffff;
  font-family: sofia-pro !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 19.6px !important;
}