.wip {
  font-size: 10px;
  letter-spacing: 1px;
  outline: 1px solid rgba(255, 0, 0, 0.5);
  outline-offset: 4px;
  position: relative;
}

.wip::after {
  content: "WIP";
  position: absolute;
  top: -4px;
  right: -4px;
  padding-right: 4px;
  padding-left: 5px;
  padding-bottom: 1px;
  color: white;
  background: rgba(255, 0, 0, 0.5);
  z-index: 10;
  pointer-events: none;
}

.env-ribbon {
  position: fixed;
  top: -120px;
  left: -120px;
  width: 200px;
  height: 200px;
  background: #f55353;
  z-index: 998;
  transform: rotate(-45deg);
  font-family: sofia-pro;
  transition: width 125ms, height 125ms;
  box-shadow: 0px 4px 4px 0px #00000026;
  display: flex;
  color: #b53e3e;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 0px;
  overflow: hidden;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.env-ribbon:active {
  filter: brightness(90%);
}

.env-ribbon.env-test {
  background: #89f452;
  color: #65b53e;
}

.env-ribbon.env-sand {
  background: #f5da53;
  color: #b5a13e;
}

.env-ribbon.env-prod-eu {
  background: #003399;
  color: #001E59;
}

.env-ribbon > div {
  position: absolute;
  top: 168px;
  width: 100%;
  text-align: center;
  transition: margin-bottom 125ms;
  font-family: sofia-pro;
  letter-spacing: 1px;
}

.env-ribbon > div > div:first-child {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.env-ribbon > div > div:last-child {
  font-size: 14px;
  font-weight: normal;
  opacity: 0;
  transition: opacity 125ms;
}

.env-ribbon:hover {
  width: 226px;
  height: 226px;
}

.env-ribbon:hover > div > div:last-child {
  opacity: 1;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.capitalize {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(92, 92, 92, 0.8);
  border-radius: 100px;
}
